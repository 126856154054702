<template>
  <div class="wrap">
    <div class="header">
      <img src="/images/vr/banner.png" class="town-pic"/>
      <img src="/images/vr/title.png" class="title-img">
    </div>
    <div class="content">
      <h3 class="content-title">小镇VR</h3>
      <ul class="content-list">
        <li class="list-item" v-for="(item,index) in list" :key="index"
        @click="goUrl(item.link)"
        >
          <img class="item-cover" :src="item.picture" alt="" />
          <div class="tag">
            <p class="tag-text">
              {{item.townName}}
              <span class="tag-date">
                发布时间：{{$date.moment(item.createDate,'YYYY-MM-DD')}}
              </span>
            </p>
          </div>
        </li>
      </ul>
      <NoData :visible="!list.length" />
      <div class="page">
        <Page
          class="page-tools"
          :size="params.pageSize"
          :current="pageNum"
          :total="total"
          @pageChange="pageChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import NoData from '@/components/common/nodata'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import Page from '@/components/common/pagination'
import { useList, useParams } from '@/hooks/useList'
import api from '@/api'
import { formatDate } from '@/utils/tools'
export default {
  name: 'vr',
  components: {
    Page,
    NoData
  },
  setup () {
    const route = useRoute()
    const filter = list => {
      return list.map(item => ({
        ...item,
        createDate: formatDate(item.createDate)
      }))
    }
    const { params, search, } = useParams({
      pageNum: route.query?.pn ?? 1,
      pageSize: 5,
      tabs:'VR'

    })
    const { total, pageNum, list, getList } = useList(params, api.vrlist, filter)
    onBeforeRouteUpdate((to) => {
      search({
        pageNum: to.query?.pn
      })
    })

    return {
      total,
      pageNum,
      params,
      list,
      getList,
      search
    }

  },
  methods: {
    goUrl (url) {
      window.open(url, '_target')
    },
    pageChange (page) {
      this.$router.push({
        query: {
          pn: page
        }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.wrap {
  display: flex;
  min-height: calc(100vh - 1.06rem);
  justify-content: center;

  background: #ffffff;
  flex-direction: column;
  align-items: center;

  .content {
    width: 12rem;
    padding: 0.6rem 0;
    min-height: calc(100vh - 1.06rem);
    .content-title {
      font-size: 0.3rem;
      font-weight: bold;
      color: #333333;
    }
    .content-subTitle {
      font-size: 0.14rem;
      font-weight: normal;
      color: #999999;
    }
    .content-list {
      margin-top: 0.35rem;
      .list-item {
        position: relative;
        margin-bottom: 0.2rem;
        transition: all 300ms;
        display: flex;
        position: relative;


        &:hover {
          transition: all 300ms;
          transform: scale(1.01);
          cursor: pointer;
        }
        .tag {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 0.6rem;
          background: #01398D;
          clip-path: polygon(
                  0 0,
                  calc(100% - 0.3rem) 0,
                  100% 0.6rem,
                  0 100%
          );


          .tag-text {
            color: #ffffff;
            font-size: 0.2rem;
            padding: 0.16rem 0 0.16rem 0.3rem;


            .tag-date {
              font-size: 0.14rem;
              margin: 0 0.6rem  0 0.3rem;
            }
          }
        }

        .item-cover {
          display: block;
          width: 100%;
          height: 4.5rem;
          object-fit: cover;
        }
        .item-title {
          margin-top: 0.12rem;
          width: 3.2rem;
          margin-left: 0.2rem;
          font-size: 0.16rem;
          font-weight: bold;
          color: #333333;
          @extend .text-oneLine;
        }
        .item-date {
          margin-left: 0.2rem;
          font-size: 0.14rem;
          color: #999999;
          font-weight: normal;
        }
      }
    }
  }
  .page {
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;
  }
  .header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    width: 100%;

    .town-pic {
      width: 100%;
      height: 7.36rem;
    }

    .title-img {
      width: 6.4rem;
      height: 1.6rem;
      position: absolute;
    }
  }
}
</style>
